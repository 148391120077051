<!--  -->
<template>
    <div class="entrance">
        <div class="Header flex-align-between">
            <div class="entrances">
                <div class="head flex-align" @click="goUser">
                    <img :src="avatar1" v-if="succeed" />
                    <img :src="require('@/assets/user/user.png')" v-else />
                </div>
                <div class="type">
                    <ul class="flex-between">
                        <li class="flex-column-center" v-for="(item, index) in tackclass" :key="index">
                            <img :src="index == iconIndex ? item.imgs2 : item.imgs1" @click="site(item.name, index)" />
                            <span @click="site(item.name, index)"
                                :style="{ color: index == iconIndex ? '#FBAE57' : '' }">{{ item.name }}</span>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="back flex-align-between">
                <img :src="require('../../assets/task/return.png')" @click="goback" />
                <span @click="goback">返回</span>
            </div>
        </div>
    </div>
</template>

<script>
    import {
        mapState,
        mapMutations
    } from "vuex";
    export default {
        data() {
            return {
                tackclass: [{
                        name: "首页",
                        href: "/home",
                        imgs1: require("../../assets/live/1.png"),
                        imgs2: require("../../assets/live/1-2.png"),
                    },
                    {
                        name: "上课",
                        href: "/attend",
                        imgs1: require("../../assets/live/2.png"),
                        imgs2: require("../../assets/live/2-2.png"),
                    },
                    // {
                    //   name: "活动",
                    //   href: "/attend",
                    //   imgs1: require("../../assets/live/3.png"),
                    //   imgs2: require("../../assets/live/3-2.png"),
                    // },
                    // {
                    //   name: "直播",
                    //   href: "/showreel",
                    //   imgs1: require("../../assets/live/4.png"),
                    //   imgs2: require("../../assets/live/4-2.png"),
                    // },
                    {
                        name: "AI测评",
                        href: "/showreel",
                        imgs1: require("../../assets/live/5.png"),
                        imgs2: require("../../assets/live/5-2.png"),
                    },
                ],
                avatar1: "",
                succeed: false,
                iconIndex: 0,
            };
        },
        //方法
        methods: {
            ...mapMutations(["setIndex"]),
            goback() {
                if (this.$route.name == 'videoProjection') {
                   this.$parent.goback();
                } else {
                    this.$router.go(-1);
                    this.$route.name == 'curriculum' ? sessionStorage.removeItem("type") : '';
                }
            },
            site(value, index) {
                if (value == "首页") {
                    this.$router.push({
                        path: "/home",
                    });
                    this.$emit("renewal", true);
                } else if (value == "上课") {

                    this.$router.push({
                        path: "/home",
                    });
                    this.$emit("renewal", true);
                } else if (value == "活动") {
                    this.access();
                } else if (value == "直播") {
                    this.$router.push({
                        path: "/livePanel",
                    });
                    this.$emit("renewal", true);
                } else {
                    this.$router.push({
                        path: "/curriculum",
                    });
                    this.$emit("renewal", true);
                }
            },
            errorimg(src) {
                this.avatar1 = "https://yunapi.cnuzi.com/uploads/" + src;
                // this.avatar1 = "https://apitest.lezhireading.com/uploads/" + src;
                let that = this;
                let img = new Image();
                img.src = this.avatar1;
                img.onload = function () {
                    that.succeed = true;
                };
                img.onerror = function () {
                    that.succeed = false;
                };
            },
            async access() {
                let _this = this;
                let resData = await _this.$Api.Home.getcheckTencentUser();
                if (resData.data) {
                    let data = await _this.$Api.Home.checkSchoolRedirectUrl();
                    if (data.data) {
                        window.open(data.data);
                    } else {
                        let resData = await _this.$Api.Myactivity.getActivityList();
                        if (resData.data.length == 0) {
                            _this.$emit("changTip", true);
                        } else {
                            _this.$router.push({
                                name: "activity",
                            });
                        }
                    }
                } else {
                    let resData = await _this.$Api.Myactivity.getActivityList();
                    // resData.data.length == 0
                    if (true) {
                        _this.$emit("changTip", true);
                    } else {
                        _this.$router.push({
                            name: "activity",
                        });
                    }
                }
            },
            goUser() {
                this.$router.push({
                    name: "profile",
                });
            },
            //请求信息
            getUser() {
                let that = this;
                this.$AccessPortrait.Portrait().then((res) => {
                    that.errorimg(res);
                });
            }
        },
        //生命周期 - 创建完成（访问当前this实例）
        created() {
            let that = this;
            this.getUser()
            // this.$AccessPortrait.Portrait().then((res) => {
            //   that.errorimg(res);
            // });
        },
        computed: {},
        //生命周期 - 挂载完成（访问DOM元素）
        mounted() {
            this.iconIndex = sessionStorage.getItem("iconIndex");
        },
    };
</script>
<style lang='less' scoped>
    @import "./less/entrance";
</style>